<template>
	<div>

		<!-- metro ShowTileSection, PodcastTileSection, Ad Body Section, ArticleTileSection -->
		<div v-if="store.metroDomains.includes(store.domain)">
			<HeroSection :page="store.vuepage.slotprops.heropage"></HeroSection>
			<AdSection v-if="!store.metroDomains.includes(store.domain)" adtype="header" backgroundclass="bglightgrey"></AdSection>
			<ListnrSection></ListnrSection>
			<RecentlyPlayedSection v-if="store.metroDomains.includes(store.domain)"></RecentlyPlayedSection>
			<ShowTileSection :tileshows="store.vuepage.slotprops.showtiles" backgroundclass=""></ShowTileSection>
			<PodcastTileSection v-if="store.vuepage.slotprops.podcasttiles && store.vuepage.slotprops.podcasttiles.length > 0" :tileshows="store.vuepage.slotprops.podcasttiles"></PodcastTileSection>
			<AdSection adtype="body" backgroundclass="bglightgrey"></AdSection>
			<ArticleTileSection :tilenewsitems="store.vuepage.slotprops.newstiles"></ArticleTileSection>
			<AdSection adtype="midleader"></AdSection>
		</div>

		<!-- regional News 1, Ad Body, News 2, Ad Midleader Section, PodcastTileSection, ShowTileSection, Ad Footer, Footer -->
		<div v-if="!store.metroDomains.includes(store.domain)">
			<HeroSectionHome25 :page="store.vuepage.slotprops.heropage"></HeroSectionHome25>
			<AdSection v-if="!store.metroDomains.includes(store.domain)" adtype="header" backgroundclass="bglightgrey"></AdSection>
			<ListnrSection></ListnrSection>
			<ArticleTileSection :tilenewsitems="firstNewsArticles"></ArticleTileSection>
			<AdSection adtype="body" backgroundclass="bglightgrey"></AdSection>
			<ArticleTileSection :tilenewsitems="secondNewsArticles"></ArticleTileSection>
			<AdSection adtype="lowerbody" backgroundclass="bglightgrey"></AdSection>
			<PodcastTileSection v-if="store.vuepage.slotprops.podcasttiles && store.vuepage.slotprops.podcasttiles.length > 0" :tileshows="store.vuepage.slotprops.podcasttiles"></PodcastTileSection>
			<ShowTileSection :tileshows="store.vuepage.slotprops.showtiles" backgroundclass="bgblack"></ShowTileSection>
		</div>

		<FooterSection></FooterSection>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import AdSection from "@/components/AdSection";
import ShowTileSection from "@/components/ShowTileSection";
import PodcastTileSection from "@/components/PodcastTileSection";
import ArticleTileSection from "@/components/ArticleTileSection";
import FooterSection from "@/components/FooterSection";
import RecentlyPlayedSection from "@/components/RecentlyPlayedSection";
import ListnrSection from "@/components/ListnrSection.vue";
import HeroSectionHome25 from "@/components/HeroSectionHome25.vue";
import HeroSection from "@/components/HeroSection.vue";
export default {
	name: "HomePage",
	components: {
		HeroSection,
		HeroSectionHome25,
		ListnrSection,
		RecentlyPlayedSection,
		FooterSection,
		ArticleTileSection,
		PodcastTileSection,
		ShowTileSection,
		AdSection,
	},
	props: {

	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
		}
	},
	computed: {
		vp() {
			return this.store.vuepage;
		},
		firstNewsArticles() {
			let nts = this.store.vuepage.slotprops.newstiles
			if(!this.store.metroDomains.includes(this.store.domain)) { //regional
				if(nts && nts.length > 0) {
					return nts.slice(0, 8)
				}
			}
			return nts //metro
		},
		secondNewsArticles() {
			let nts = this.store.vuepage.slotprops.newstiles
			if(!this.store.metroDomains.includes(this.store.domain)) { //regional
				if(nts && nts.length > 0) {
					return nts.slice(8, 16)
				}
			}
			return [] //metro
		},
	},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>

</style>