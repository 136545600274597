<template>
	<div class="hero-section">
		<div class="hero-image-holder" :style="'backgroundImage: url('+bgimg+')'"></div>
		<div class="container-parent">
			<div v-if="store.loading === false" class="container structure">
				<div class="left-half">
					<h1 class="text-white">{{page.heading}}</h1>
					<p class="text-white" v-html="page.subheading"></p>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: "HeroSectionForm",
	components: {

	},
	props: {
		page: Object,
		showlinkbutton: Boolean,
	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
		}
	},
	computed: {
		bgimg() {
			if(this.store.loading === false && this.page.hero_image !== '') {
				return this.page.hero_image;
			}
			return '';
		},
	},
	methods: {},
	watch: {

	},
	mounted() {

	}
}
</script>

<style scoped>
h1 {
	font-family: var(--secondary-font);
}
.hero-section {
	height: 500px;
	max-height: 500px;
	position: relative;
}
.container-parent {
	margin: 0 auto;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.am .container-parent {
	background-image: linear-gradient(to right, rgba(0,45,88,0.8) 0%, rgba(24,128,226,0.8) 100%);
}
.fm .container-parent {
	background-image: linear-gradient(to right, rgba(43,0,51,0.8) 0%, rgba(170,4,85,0.8) 100%);
}
.mp .container-parent {
	background-image: linear-gradient(to right, rgba(0,73,95,0.8) 0%, rgba(102,201,213,0.8) 100%);
}
.magic .container-parent {
	background-image: linear-gradient(to right, rgba(240,99,19,0.8) 0%, rgba(228,160,49,0.8) 100%);
}
.container {
	max-height: 100%;
}
.hero-image-holder {
	height: 100%;
	width: 100%;
	background-size: cover;
	background-repeat: no-repeat;
}
.structure {
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
	height: 100%;
}
.left-half {
	width: 49%;
}

@media screen and (max-width: 959px) {
	.am .hero-section {
		background-image: url('/public-static/backgrounds/home-hero-bg-mob-am.jpg');
	}
	.fm .hero-section {
		background-image: url('/public-static/backgrounds/home-hero-bg-mob-fm.jpg');
	}
	.mp .hero-section {
		background-image: url('/public-static/backgrounds/home-hero-bg-mob-mp.jpg');
	}
	.magic .hero-section {
		background-image: url('/public-static/backgrounds/home-hero-bg-mob-magic.jpg');
	}
}
@media screen and (max-width: 900px) {
	.hero-section {
		height: 400px;
		max-height: 400px;
		position: relative;
	}
}
@media screen and (max-width: 640px) {
	.hero-section {
		height: 300px;
		max-height: 300px;
		position: relative;
	}
	.left-half {
		width: 100%;
	}
}
</style>